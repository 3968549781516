.mainLayout {
  flex: 1;
  padding: 8px 8px 0;
  overflow: hidden;
  gap: 8px;
  display: flex;
  flex-direction: column;
}

.memberSettings {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.content {
  display: flex;
  gap: 8px;
  height: calc(100% - 34px);
}
