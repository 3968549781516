.collapseButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
}
.collapseButton:hover {
  background-color: var(--palette-neutrals-lightGrey);
}
