.attachedFiles {
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: auto;
  gap: 8px 12px;
}

.attachment {
  display: flex;
  align-items: center;
  gap: 4px;
  overflow: hidden;
  cursor: pointer;
}

.imageContainer {
  display: flex;
  align-items: center;
  gap: 4px;
}

.fileName {
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.15px;
  color: var(--palette-primary-purple);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: calc(100vw - 900px);
}

.deleteFile {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 13px;
}

.uploadingFile {
  max-width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.uploadingLabel {
  font-weight: 600;
  font-size: 14px;
  color: var(--palette-secondary-darkBlue);
  white-space: nowrap;
}

.downloadIcon {
  color: var(--palette-primary-purple);
}

.downloadIcon:hover {
  color: var(--palette-primary-darkPurple);
}

.cancelIcon {
  border-radius: 50%;
  color: #929398 !important;
}

.cancelIcon:hover {
  color: var(--palette-secondary-darkBlue) !important;
}
