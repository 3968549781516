.informationWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.informationRow {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.fullWidth {
  width: calc(100% - 100px);
}

.header {
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.uploader {
  width: 100% !important;
}

.fixedWidth {
  width: 164px;
}
