.container {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.icon {
  width: 36px !important;
  height: 36px !important;
}

.contentRoot {
  width: calc(100% - 40px);
  display: flex;
  flex-direction: column;
}

.contentItem {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 8px 12px;
  gap: 6px;
  border-radius: 12px;
  background: #fff;
}

.editContentItem {
  background: var(--palette-neutrals-tableLightGray);
  border: 1px solid #d3d3d3;
}

.errorContainer {
  border: 1px solid var(--palette-ui-error) !important;
}

.errorLabel {
  color: var(--palette-ui-error) !important;
  margin-top: 4px !important;
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
}

.title {
  font-weight: 700;
  font-size: 14px;
  line-height: 143%;
  letter-spacing: 0.17px;
  color: var(--palette-secondary-darkBlue);
}

.lastUpdateAt {
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  letter-spacing: 0.17px;
  color: var(--palette-neutrals-grayBlueDark);
}

.messageContainer {
  display: flex;
  justify-content: space-between;
}

.messageContent {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.15px;
  color: black !important;
  word-break: break-word;
  white-space: pre-line !important;
}

.deleteCommentContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: hidden;
}

.deleteModalTitle {
  font-size: 24px;
  justify-content: center !important;
}

.deleteCommentSubtitle {
  text-align: center !important;
  font-weight: 400;
}

.buttonGroup {
  margin-top: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.attachButton {
  background: transparent !important;
  min-width: 24px !important;
  width: 24px !important;
  padding: 0px !important;
}

.attachIcon {
  color: #afafaf !important;
}

.attachIcon:hover {
  cursor: pointer;
  color: var(--palette-secondary-darkBlue) !important;
}

.actionContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.attachmentArea {
  max-height: 246px;
  overflow-y: auto;
}
