.content {
  flex: 1;
  padding: 8px 0px 8px 8px;
  overflow: hidden;
}

.container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.title {
  text-transform: capitalize !important;
}

.tableBlock {
  display: flex;
  flex-direction: column;
  min-height: 1px;
  flex: 1;
}

.hidden {
  display: none;
}

.tableContainer {
  width: 100%;
  overflow: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 4px;
}

.headCell {
  padding: 4px !important;
  white-space: nowrap;
  border-bottom: 1px solid rgba(211, 211, 211, 0.4) !important;
}

.headFirstCell {
  border-radius: 4px 0 0 0;
  padding-left: 12px !important;
}

.headCellLast {
  border-radius: 0 4px 0 0;
  width: 1%;
  padding-right: 24px !important;
}

.sortIcon {
  width: 24px;
  height: 24px;
  color: var(--palette-secondary-darkBlue) !important;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px 3px 10px;
}

.versionLabel {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 4px;
  margin-right: 24px;
  color: var(--palette-neutrals-greyMed);
  font-size: 14px;
  font-weight: 400;
}

.truncatedText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
