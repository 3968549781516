.tableContainer .MuiDataGrid-root {
  border: none !important;
  cursor: pointer;
}
.tableContainer .MuiDataGrid-columnHeader {
  padding: 0 4px !important;
}
.tableContainer .MuiDataGrid-columnHeaderTitle {
  color: var(--palette-secondary-darkBlue);
  font-size: 12px;
  font-weight: 600;
}
.tableContainer .MuiDataGrid-cell {
  border: none !important;
  align-content: center;
  color: var(--palette-secondary-darkBlue);
}
.tableContainer .MuiButtonBase-root {
  font-size: 14px;
  padding: 0;
}
.tableContainer .MuiDataGrid-row.Mui-selected,
.tableContainer .MuiDataGrid-row.Mui-selected:hover {
  background-color: var(--palette-secondary-lightBlue);
}
.tableContainer .MuiDataGrid-row:hover {
  background-color: transparent;
  box-shadow: 0px 4px 4px rgba(177, 185, 205, 0.5);
}
.tableContainer .MuiDataGrid-scrollbar--vertical {
  width: 8px;
}
.tableContainer .MuiDataGrid-scrollbar--horizontal {
  height: 8px;
}

.customVirtualScroll {
  background-color: #fff;
  max-height: 100%;
  height: auto !important;
  padding-bottom: 8px;
}

.scrollArea {
  background-color: #fff;
  border-radius: 8px;
}

.customSelected {
  background: var(--palette-secondary-lightBlue) !important;
}