.topNavigationLayout {
  margin: 0;
  background-color: var(--palette-neutrals-white);
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.05);
  position: relative;
  height: 48px;
  line-height: 48px;
  white-space: nowrap;
  overflow: auto hidden;
  z-index: 2;
}

.topNavigationContainer {
  display: flex;
  align-items: center;
  padding: 4px 18px 0px 24px;
}

.logoIconWrapper {
  display: flex;
  align-items: center;
  width: 120px;
  height: 24px;
}

.defaultLogoWrapper {
  width: 100%;
}

.logoIcon {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.chip {
  margin-left: 12px;
  font-style: italic;
}

.role {
  margin-left: 12px;
  width: 54px;
  height: 24px;
  background: var(--palette-secondary-salmon);
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.46px;
  text-transform: capitalize;
  cursor: default;
  color: black;
}

.versionLabel {
  display: flex;
  margin: 0 8px;
  color: var(--palette-neutrals-greyMed);
  font-size: 14px;
  font-weight: 400;
}

.subAppsGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  left: 312px;
  gap: 28px;
  cursor: pointer;
}

.subAppTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 157%;
  letter-spacing: 0.1px;
  color: var(--palette-secondary-darkBlue);
}

.activeSubTitle {
  font-weight: 700;
}

.subAppTitleUnderline {
  background: var(--palette-secondary-salmon);
  border-radius: 2px;
  width: 100%;
  height: 4px;
}

.hide {
  visibility: hidden;
}

.actionsWrapper {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.settingsContainer {
  display: flex;
  align-items: center;
  margin-left: 114px;
}

.settingsGroup {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--palette-secondary-darkBlue);
  margin-left: 8px;
}

.settingsGroup:hover {
  cursor: pointer;
}

.nameLabel {
  margin-right: -6px !important;
  text-transform: capitalize;
}

.menuList {
  background-color: white;
}

.link {
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  text-decoration: none;
}
