.detailListContainer {
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--palette-neutrals-grayBlue);
  position: relative;
  padding: 0 4px 4px 0;
}

.detailListContainer::-webkit-scrollbar {
  width: 6px;
}

.detailListContainer::-webkit-scrollbar-track {
  border-radius: 100px;
  margin-bottom: 4px;
}

.detailListContainer::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  border-radius: 100px;
  border: 0px solid rgba(0, 0, 0, 0.01);
}
