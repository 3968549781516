.typeaheadV2 {
  display: flex;
  align-items: center;
  width: fit-content;
  height: 26px;
  border-radius: 30px;
  background-color: #f0f2f7;
  border: 1px solid #f0f2f7;
  padding: 0px 6px 0px 12px;
  cursor: pointer;
  position: relative;
}

.typeaheadV2:focus {
  border: 1px solid #5d36ef !important;
  outline-style: none;
}

.typeaheadV2.selected {
  border: 1px solid #5d36ef;
}

.typeaheadV2__selectedContainer {
  background-color: #ebe5ff !important;
}

.typeaheadV2__assignTo {
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.46px;
  text-transform: capitalize;
  color: var(--palette-secondary-darkBlue);
}

.typeaheadV2__assignName {
  font-weight: 700;
}

.typeaheadV2__moreIcon {
  display: flex;
  align-items: center;
  margin-left: 6px;
  color: var(--palette-secondary-darkBlue);
  transform: rotate(0deg);
}

.typeaheadV2__moreIcon.open {
  transform: rotate(180deg);
}

.typeaheadV2__paper {
  margin-top: 8px;
  min-width: 265px !important;
  height: 300px !important;
  padding: 20px 0px 20px 20px;
  border-radius: 26px !important;
  overflow: hidden !important;
  box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.15) !important;
}

.typeaheadV2__popoverContent {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
}

.typeaheadV2__searchMembers {
  margin-right: 20px !important;
}

.typeaheadV2_inputRoot {
  border-radius: 8px !important;
  border: 1px solid rgba(211, 211, 211, 0.6) !important;
  margin: 8px 0px 10px 0px !important;
}

.typeaheadV2_inputInput {
  height: 22px !important;
  border-radius: 8px !important;
  padding: 9px 12px !important;
  border-color: transparent !important;
}

.typeaheadV2_inputNotchedOutline {
  border-color: transparent !important;
}

.typeaheadV2_inputFocused {
  border: 1px solid var(--palette-primary-purple) !important;
}

.typeaheadV2__listWrapper2 {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 4px;
  overflow-y: auto;
  max-height: 250px;
  padding-top: 8px;
}

.typeaheadV2__listWrapper2::-webkit-scrollbar {
  width: 16px;
}

.typeaheadV2__listWrapper2::-webkit-scrollbar-track {
  border-radius: 100px;
  margin-bottom: 4px;
  margin-top: 8px;
}

.typeaheadV2__listWrapper2::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  border-radius: 100px;
  border: 5px solid rgba(0, 0, 0, 0.01);
  min-height: 80px;
}

.typeaheadV2__noMatchingWrapper {
  margin: auto;
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
}

.typeaheadV2__noMatchingIcon {
  color: var(--palette-secondary-darkBlue);
  width: 56px !important;
  height: 56px !important;
  margin: 12px 0px;
}

.typeaheadV2__noMatchingLabel {
  color: var(--palette-neutrals-grayBlueDark);
}

.typeaheadV2__buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
