.filtersGroup {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.filtersContainer {
  display: flex;
  gap: 10px;
}

.verticalItem{
  width: calc(100% - 32px) !important;
}

.filterItem {
  display: inline-block;
  white-space: nowrap;
}

.menuItem {
  padding: 4px 0px 4px 20px !important;
}

.searchMembers {
  display: flex;
  justify-content: space-between;
  min-width: 184px;
  width: fit-content;
  height: 34px !important;
  border: 1px solid #d3d3d3;
  border-radius: 58px !important;
  padding: 0px 12px 0px 18px !important;
  background-color: #fff;
}

.moreIcon {
  color: var(--palette-primary-purple) !important;
}

.orgsDropdown {
  width: 204px !important;
  height: 22px !important;
}
