.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-left: 16px;
}

.emptyContent {
  display: flex;
  height: 100%;
  margin-top: 38px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 46px;
}

.content::-webkit-scrollbar {
  width: 8px;
}

.content::-webkit-scrollbar-track {
  border-radius: 8px;
}

.content::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  background: #b8b9bb;
  border-radius: 8px;
}

.editButton {
  position: absolute !important;
  left: 24px;
  bottom: 20px;
}

.actionButtonWrapper {
  padding: 12px 0 0 16px;
  background-color: var(--palette-neutrals-grayBlue);
  display: flex;
  align-items: center;
}

.row {
  display: flex;
  gap: 8px;
}
