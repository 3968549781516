.accountPageContainer {
  width: 100%;
  text-align: left;
  height: calc(100vh - 110px);
  touch-action: manipulation !important;
  overflow: auto;
  min-width: 600px;
  scrollbar-width: thin;
}

.organizationSettings {
  padding: 0px 12px 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.bordered {
  border: 1px solid #d3d3d3 !important;
}

.infoGroupContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.orgDropdown {
  width: 416px !important;
}

.organizationRow {
  display: flex;
  align-items: center;
  gap: 8px;
}

.infoLabel {
  display: flex;
  justify-content: space-between;
  width: 120px !important;
}

.button {
  width: 100px;
}

.divideLine {
  width: 100%;
  height: 0px;
  border-bottom: 1px solid #24242433;
}

.removeIcon {
  position: absolute !important;
  right: -30px;
  top: -16px;
}

.logoContainer {
  width: 466px !important;
  height: 24px !important;
  border-radius: 16px;
}

.logoArea {
  background: transparent;
  display: flex;
  background: transparent !important;
  border: none !important;
  border-radius: 4px;
}

.hasFile {
  align-items: flex-start !important;
}

.editingBackground {
  background-color: white !important;
  border: 1px solid #d3d3d3 !important;
  border-radius: 8px !important;
  padding-left: 8px !important;
  width: unset !important;
}

.logoImage {
  width: 120px !important;
  height: 22px !important;
  background: transparent !important;
  border: none !important;
}

.tableContainer {
  width: 620px;
}

.table {
  table-layout: fixed;
}

.headCell {
  padding: 0px !important;
  white-space: nowrap;
  padding: 0px !important;
  background: #f8f8f8 !important;
  border-bottom: 0px solid rgba(211, 211, 211, 0.4) !important;
}
.eventCell {
  padding: 2px 0px !important;
  border-bottom: 0px !important;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 2px 8px 6px;
  border-radius: 8px;
  background: #ebedf4;
}

.servicesDropdown {
  width: 516px !important;
}

.fullWidth {
  width: 100%;
}