.sideContainer {
  display: flex;
  z-index: 10;
  flex-direction: column;
  background-color: var(--palette-secondary-darkBlue);
  height: 100%;
  min-width: 150px;
}

.sideContent {
  position: relative;
  padding: 12px 0;
  height: calc(100% - 16px);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: unset !important;
  overflow: hidden;
}

.topItem {
  display: flex;
  flex-direction: column;
  padding-top: 5px;
}

.body {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 10px;
  overflow-y: auto;
}

.body::-webkit-scrollbar {
  width: 0;
}

.body:hover::-webkit-scrollbar {
  width: 8px;
}

.body:hover::-webkit-scrollbar-track {
  border-radius: 8px;
}

.body:hover::-webkit-scrollbar-thumb {
  background: #546596;
  border-radius: 8px;
}

.accordionRoot {
  height: fit-content !important;
  background-color: transparent !important;
}

.accordionSummary {
  height: 32px !important;
  min-height: 32px !important;
  padding: 0px !important;
  flex-direction: row-reverse;
}

.accordionSummary:hover {
  background: rgba(255, 255, 255, 0.15);
  color: var(--palette-secondary-contrast);
  font-weight: 400;
}

.accordionSummary:hover .expandIcon {
  color: var(--palette-primary-darkPurple) !important;
}

.accordionSummaryContent {
  padding-right: 0px !important;
  margin: 0px !important;
}

.expandIcon {
  color: var(--palette-primary-purple) !important;
}

.accordionExpanded {
  background-color: transparent !important;
}

.accordionDetails {
  padding: 0 !important;
  gap: 0 !important;
  overflow: hidden;
}

.searchRoot {
  width: calc(100% - 24px);
  margin-left: 12px;
  margin-bottom: 6px;
}

.navItemContainer {
  color: rgba(255, 255, 255, 0.6);
  font-size: 16px;
  letter-spacing: 0.15px;
  font-weight: 400;
  text-transform: capitalize;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  height: 32px;
}

.allItemHover:hover {
  color: #ffffff;
  background: #132c73 !important;
  font-weight: 400;
}

.childItemWithMore {
  flex: 1;
}

.childItem {
  flex: 1;
}

.itemSelected {
  background: #132c73 !important;
  color: rgba(255, 255, 255, 0.8) !important;
}

.itemSelected .sideWrapper {
  background-color: #2fd6ee;
}

.labelWrapper {
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.label {
  display: flex;
  line-height: 24px;
  align-items: center;
}

.labelSpan {
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.moreIcon {
  padding: 2px;
  transform: rotate(-90deg);
  color: #fff;
  font-size: 1rem !important;
  border-radius: 3px;
}

.moreIcon:hover {
  background: rgba(255, 255, 255, 0.1) !important;
}

.selectedMoreIcon {
  padding: 2px;
  transform: rotate(-90deg);
  color: #fff;
  font-size: 1rem !important;
  border-radius: 3px;
}

.selectedMoreIcon:hover {
  background: rgba(255, 255, 255, 0.1) !important;
}

.navOrgIcon {
  width: 15px !important;
  height: 13px !important;
  background: #fff;
  padding: 6px 5px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 100px;
  cursor: pointer;
  transform: rotate(180deg) !important;
}

.navOrgIcon:hover {
  background: var(--grayBlue) !important;
}

.link {
  text-decoration: none;
}

.popoverContainer {
  padding: 0;
}

.arrowContainer {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebarPopover .MuiPopover-paper {
  padding: 8px 0px !important;
  margin-top: -4px;
  margin-left: 17px;
  max-width: 140px;
  min-width: unset !important;
}

.sidebarPopover .popoverHeader {
  padding-left: 12px;
  padding-bottom: 5px;
  border-bottom: 1px solid #eae6e6;
  margin-bottom: 7px;
}

.sidebarPopover .popoverContainer {
  padding: 0;
}

.sidebarPopover .popoverContainer li {
  padding: 0 12px;
}

#SideMenu_accounts_accordion_content:hover {
  background: rgba(255, 255, 255, 0.1) !important;
  border-radius: unset !important;
}

#SideMenu_sideMenuItem_group {
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.3) #081949;
}
