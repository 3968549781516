.Panel {
  display: flex;
  flex-direction: column;
  padding: 0 4px;
  gap: 8px;
}

.PanelContent {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: auto;
  border-radius: 0.5rem;
}

.ResizeHandleOuter {
  flex: 0 0 8px;
  position: relative;
  outline: none;

  --background-color: var(--palette-neutrals-lightGrey);
  --handlebar-position: 3px;
}
.ResizeHandleOuter[data-resize-handle-active],
.ResizeHandleOuter[data-resize-handle-state='hover'] {
  --background-color: var(--palette-secondary-lightBlue);
  --handlebar-position: 1px;
}

.ResizeHandleInner {
  position: absolute;
  border-radius: 4px;
  top: var(--handlebar-position);
  bottom: var(--handlebar-position);
  left: var(--handlebar-position);
  right: var(--handlebar-position);
  background-color: var(--background-color);
  transition: background-color 0.2s linear;
}

.verticalResizeHandle {
  cursor: n-resize !important;
}

.horizontalResizeHandle {
  cursor: e-resize !important;
}

.Icon {
  width: 1em;
  height: 1em;
  position: absolute;
  left: calc(50% - 0.5rem);
  top: calc(50% - 0.5rem);
}
