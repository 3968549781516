.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buttonsGroup {
  display: flex;
  align-items: center;
  gap: 8px;
}

.popoverHeader {
  display: flex;
  justify-content: space-between;
}

.searchRoot {
  margin-right: 8px;
}

.searchContainer {
  flex-direction: row !important;
}

.searchIcon {
  /* margin-top: 24px; */
  color: var(--palette-secondary-darkBlue);
  cursor: pointer;
}

.chip {
  margin-left: 8px;
}
