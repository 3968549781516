.bodyLastLeftCell {
    border-bottom-left-radius: 10px;
  }
  
  .bodyLastRightCell {
    border-bottom-right-radius: 10px;
  }
  
  .tableRow {
    display: table-row;
    text-decoration: none;
    background: #F8F8F8 !important;
  }
  
  .tableRow:hover {
    cursor: pointer;
    box-shadow: 0px 4px 4px rgba(177, 185, 205, 0.5) !important;
    position: sticky;
  }
  
  .selected {
    background: var(--palette-secondary-lightBlue) !important; 
  }
  