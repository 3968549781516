.assignPaper {
  margin-top: 8px;
  box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.15) !important;
  border-radius: 8px !important;
  border: 1px solid #fff !important;
}

.autoComplete {
  height: 22px;
  border-radius: 8px;
  border: 1px solid rgba(211, 211, 211, 0.6);
  flex: 1;
}

.textField {
  font-size: 14px !important;
  border-radius: 8px !important;
  padding: 0px 2px!important;
  margin: 0px !important;
  height: 22px !important;
}

.noBorder {
  border: none !important;
}

.autoCompleteSelected {
  border: 1px solid var(--palette-primary-purple) !important;
}

.item{
  border-radius: 4px;
}
.item:hover {
  border: 1px solid var(--palette-primary-purple) !important;
}
