.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.uploadedFile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  margin: 7px 0;
  padding: 8px 20px;
}

.topic {
  display: flex;
  align-items: center;
  gap: 10px;
}

.contentItem {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
}

.deleteButton {
  color: var(--palette-ui-error) !important;
  padding: 4px !important;
}

.deleteButton:hover {
  color: var(--palette-secondary-main) !important;
}

.secondaryButton {
  font-weight: 700 !important;
  font-size: 14px !important;
  letter-spacing: 0.4px !important;
  line-height: 24px !important;
  padding: 6px 14px !important;
  font-family: 'Open Sans' !important;
  width: 80px;
  height: 42px;
  border-radius: 26px !important;
  box-shadow: none !important;
  text-transform: capitalize !important;
  background-color: #ffffff !important;
  color: var(--palette-neutrals-gray) !important;
  border: 2px solid var(--palette-neutrals-lightGrey) !important;
}

.secondaryButton:hover {
  border: 2px solid var(--palette-primary-purple) !important;
}

.row {
  display: flex;
  gap: 8px;
  align-items: flex-start;
}

.helpIcon {
  color: var(--palette-neutrals-grey);
  font-size: 13px !important;
  margin-top: 4px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border-radius: 16px;
  background: #ebedf4;
}

.tooltip {
  max-width: 170px !important;
}
