.assignContainer {
  display: flex;
  align-items: center;
  width: fit-content;
  height: 26px;
  border-radius: 30px;
  background-color: #f0f2f7;
  border: 1px solid #f0f2f7;
  padding: 0px 6px 0px 12px;
  cursor: pointer;
  position: relative;
}

.assignContainer.selected {
  border: 1px solid #5d36ef;
}

.assignTo {
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.46px;
  text-transform: capitalize;
  color: var(--palette-secondary-darkBlue);
}

.assignName {
  font-weight: 700;
}

.moreIcon {
  display: flex;
  align-items: center;
  margin-left: 6px;
  color: var(--palette-secondary-darkBlue);
  transform: rotate(0deg);
}

.moreIcon.open {
  transform: rotate(180deg);
}

.popover {
  display: flex;
  flex-direction: column;
}

.assignPaper {
  margin-top: 8px;
  box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.15) !important;
  border-radius: 26px !important;
  border: 1px solid #fff !important;
}

.autoComplete {
  margin-top: 5px !important;
  height: 42px;
  border-radius: 8px;
  border: 1px solid rgba(211, 211, 211, 0.6);
}

.textField {
  border-radius: 8px !important;
  padding: 0px !important;
  height: 42px !important;
}

.noBorder {
  border: none !important;
}

.autoCompleteSelected {
  border: 1px solid var(--palette-primary-purple) !important;
}

.tagChipWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 8px;
  padding: 1px 8px 1px 2px;
  gap: 8px;
  background: #f0f2f7;
  border-radius: 100px;
}

.tagChip {
  font-weight: 700 !important;
  font-size: 9px !important;
  line-height: 150% !important;
  letter-spacing: 0.075px !important;
  color: var(--palette-neutrals-grey) !important;
}

.tagName {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  white-space: nowrap;
  color: var(--palette-secondary-darkBlue);
}

.tagCloseIcon {
  font-size: 16px !important;
  color: var(--palette-secondary-darkBlue);
}

.tagCloseIcon:hover {
  color: var(--palette-neutrals-lightGrey);
  cursor: pointer;
}

.statusMenuItem {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-top: 2px;
  padding-left: 8px;
  padding-bottom: 2px;
}

.ccTitle {
  margin-top: 16px;
  margin-bottom: 1px;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.15px;
}

.ccRoot {
  min-height: 76px;
  border-radius: 8px;
  border: 1px solid rgba(211, 211, 211, 0.6);
}

.ccChip {
  height: 26px !important;
  background: #f0f2f7 !important;
  border-radius: 100px !important;
  margin: 4px !important;
}

.ccChipError {
  background: #f34e4e !important;
}

.ccChipLabel {
  padding: 8px !important;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: var(--palette-secondary-darkBlue) !important;
}

.ccChipLabelError {
  color: #fff !important;
}

.ccDeleteIcon {
  font-size: 16px !important;
  fill: var(--palette-secondary-darkBlue) !important;
}

.ccDeleteIconError {
  fill: #fff !important;
}

.ccDeleteIcon:hover {
  fill: var(--palette-neutrals-lightGrey) !important;
}
