.filtersRoot {
  padding-right: 8px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
}

.filtersContainer {
  display: flex;
  gap: 10px;
  align-items: center;
  overflow: auto hidden;
}

.actionsContainer {
  display: flex;
}

.filterButtons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.filterWrapper:hover {
  border: 2px solid var(--palette-primary-purple);
}

.filterWrapper.active {
  border: 2px solid var(--palette-primary-purple);
}

.filterLabel {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  text-transform: capitalize;
}

.chip {
  margin-left: 8px;
}

.filterWrapper > .chip {
  cursor: pointer;
}

.addIcon {
  margin-right: 2px !important;
}

.checkButtonIcon {
  margin-right: -6px !important;
}

.myListLabel {
  text-transform: none !important;
  white-space: nowrap !important;
}

.searchRoot {
  width: 270px !important;
}
