.sidebarPopover .MuiPopover-paper {
  padding: 8px 0px !important;
  margin-top: -4px;
  margin-left: 10px;
  max-width: 140px;
  min-width: unset !important;
}

.sidebarPopover .popoverHeader {
  padding-left: 12px;
  padding-bottom: 5px;
  border-bottom: 1px solid #eae6e6;
  margin-bottom: 7px;
}

.sidebarPopover .popoverContainer {
  padding: 0;
}

.sidebarPopover .popoverContainer li {
  padding: 0 12px;
}

#SideMenu_organizations_accordion_content #more-button-popover svg {
  color: rgba(255, 255, 255, 0.8) !important;
}

#SideMenu_organizations_accordion_content #more-button-popover.hovered svg {
  color: rgba(255, 255, 255, 0.8) !important;
}

#SideMenu_organizations_accordion_content #more-button-popover {
  padding: 0 5px;
}

#SideMenu_organizations_accordion_content .sideWrapper {
  width: 5px;
  height: 32px;
}

#SideMenu_accounts_accordion_content:hover {
  background: rgba(255, 255, 255, 0.1) !important;
  border-radius: unset !important;
}

#SideMenu_sideMenuItem_group {
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.3) #081949;
}
