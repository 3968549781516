.accessDropdown {
  display: flex;
  align-items: center;
  width: fit-content;
  height: 26px;
  border-radius: 30px;
  cursor: pointer;
  position: relative;
}

.accessDropdown.disabled {
  background-color: #f8f9fe !important;
  border-color: #f8f9fe !important;
}

.defaultDropdown {
  height: 34px !important;
  padding: 0 24px !important;
  background-color: #fff;
  border: 1px solid #fff;
  display: flex;
  justify-content: space-between;
}

.defaultDropdown:hover {
  border: 1px solid var(--palette-primary-purple);
}

.defaultDropdown.selected {
  border: 1px solid var(--palette-primary-purple);
}

.selectedContainer {
  background-color: #ebe5ff !important;
}

.assignTo {
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.46px;
  text-transform: capitalize;
  color: var(--palette-secondary-darkBlue);
}

.moreIcon {
  display: flex;
  align-items: center;
  margin-left: 6px;
  color: var(--palette-secondary-darkBlue);
  border-radius: 50%;
  transform: rotate(0deg);
}

.moreIcon:hover {
  background-color: var(--palette-options-hover);
}

.moreRotate {
  background-color: var(--palette-neutrals-grayBlue);
  transform: rotate(180deg);
}

.moreDefaultIcon {
  display: flex;
  align-items: center;
  margin-left: 6px;
  color: var(--palette-secondary-darkBlue);
}

.moreDefaultIcon:hover {
  color: var(--palette-primary-purple);
}

.moreDefaultRotate {
  color: var(--palette-primary-purple);
  transform: rotate(180deg);
}

.moreSvgIcon {
  font-size: 20px !important;
}

.moreDefaultSvg {
  font-size: 24px !important;
  color: var(--palette-primary-purple);
}

.searchWrapper {
  width: calc(100% - 20px);
  padding-right: 20px;
}

.searchMembers {
  margin-right: 20px;
}

.inputRoot {
  border-radius: 8px !important;
  border: 1px solid rgba(211, 211, 211, 0.6) !important;
}

.inputInput {
  height: 22px !important;
  border-radius: 8px !important;
  padding: 9px 12px !important;
  border-color: transparent !important;
}

.inputNotchedOutline {
  border-color: transparent !important;
}

.inputFocused {
  border: 1px solid var(--palette-primary-purple) !important;
}

.paper {
  padding: 20px 0px 20px 20px;
  border-radius: 26px !important;
  overflow: hidden !important;
  box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.15) !important;
}

.popoverContent {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  align-items: center;
}

.sideContainer {
  padding-top: 12px;
  display: flex;
  width: calc(100% - 16px);
  z-index: 10;
  flex-direction: column;
  gap: 4px;
  height: 288px;
  overflow: auto;
  padding-right: 14px;
}

.sideContainer::-webkit-scrollbar {
  width: 6px;
}

.sideContainer::-webkit-scrollbar-track {
  margin-top: 12px;
}

.noMatchingWrapper {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
}

.noMatchingIcon {
  color: var(--palette-secondary-darkBlue);
  width: 56px !important;
  height: 56px !important;
  margin: 12px 0px;
}

.noMatchingLabel {
  color: var(--palette-neutrals-grayBlueDark);
}

.accordionRoot {
  height: fit-content !important;
  background-color: transparent !important;
}

.accordionRoot::before,
.accordionRoot::after {
  content: " " !important;
  height: 0 !important;
}

.accordionSelectedSummary {
  border-radius: 8px !important;
  color: var(--palette-secondary-darkBlue) !important;
  font-weight: 700 !important;
}

.accordionSummary {
  height: 28px !important;
  min-height: 28px !important;
  padding: 0px !important;
  flex-direction: row-reverse;
  border-radius: 8px !important;
}

.accordionSummary:hover {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 8;
  color: var(--palette-secondary-contrast);
  font-weight: 400;
}

.accordionSummary:hover > .expandIcon {
  color: var(--palette-primary-darkPurple) !important;
}

.accordionSummaryContent {
  width: 100%;
  padding-right: 0px !important;
  margin: 0px !important;
}

.accordionExpanded {
  background-color: transparent !important;
}

.accordionExpanded::before {
  opacity: 1 !important;
}

.accordionDetails {
  padding: 5px 0px 0px 20px !important;
  overflow-y: hidden !important;
  gap: 4px !important;
}

.selectLabel {
  font-family: Open Sans !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 19px !important;
  color: var(--palette-secondary-darkBlue) !important;
  transform: none !important;
  margin-bottom: 11px;
}

.selectLabelDisabled {
  color: var(--palette-neutrals-greyMed) !important;
}

.selectLabelError {
  color: var(--palette-ui-error) !important;
}

.truncatedText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
