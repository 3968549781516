.button {
  font-weight: 600 !important;
  font-size: 13px !important;
  letter-spacing: 0.4px !important;
  line-height: 22px !important;
  padding: 4px 10px !important;
  height: 24px;
  font-family: 'Open Sans' !important;
  border-radius: 8px !important;
  box-shadow: none !important;
  text-transform: capitalize !important;
}

.primaryButton {
  background-color: var(--palette-primary-purple) !important;
  color: var(--palette-secondary-contrast) !important;
}

.primaryButton:hover {
  background-color: var(--palette-primary-darkPurple) !important;
}

.secondaryButton {
  background-color: #ffffff !important;
  color: var(--palette-neutrals-grey) !important;
  border: 1px solid var(--palette-neutrals-lightGrey) !important;
}

.secondaryButton:hover {
  border: 1px solid var(--palette-primary-purple) !important;
}

.primaryDisabled {
  background-color: var(--palette-neutrals-lightGrey) !important;
  color: var(--palette-secondary-contrast) !important;
}

.secondaryDisabled {
  background-color: #f4f4f4 !important;
  color: var(--palette-neutrals-lightGrey) !important;
  border: 1px solid var(--palette-neutrals-lightGrey) !important;
}
