.pageContainer {
  width: 100%;
  height: calc(100vh - var(--app-banner-height));
  touch-action: manipulation !important;
  overflow: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.pageWrapper {
  width: 550px;
  margin: auto;
  padding: 25px 0px;
  display: flex;
  flex-direction: column;
}

.itemWrapper {
  width: 100%;
  display: flex;
  align-items: center;
}

.envKey {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.15px;
  color: var(--palette-secondary-darkBlue);
  margin-right: 8px;
}

.envValue {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.15px;
  color: var(--palette-primary-purple);
}
