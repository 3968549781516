.timeLine {
  display: flex;
  gap: 16px;
  padding-bottom: 24px;
}

.markWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.line {
  position: absolute;
  width: 0px;
  background: var(--palette-secondary-darkBlue);
  border: 1px solid var(--palette-secondary-darkBlue);
}
