.content {
    flex: 1;
    padding: 8px 0px 8px 8px;
    overflow: hidden;
  }
  
  .container {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
  }
  
  .topContainer {
    display: flex;
    justify-content: space-between;
    padding-right: 24px;
    margin-bottom: 20px;
    gap: 24px;
    overflow: auto hidden;
  }
  
  .title {
    text-transform: capitalize !important;
  }
  
  .tableBlock {
    min-height: 1px;
    flex: 1;
  }
  
  .tableContainer {
    width: 100%;
    height: calc(100% - 34px);
    overflow: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 10px;
  }
  
  .tableContainer:hover::-webkit-scrollbar-thumb {
    background: #B8B9BB !important;
  }
  
  .tableContainer::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  
  .tableContainer::-webkit-scrollbar-track {
    border-radius: 100px;
    margin-top: 34px;
    margin-bottom: 8px;
  }
  
  .tableContainer::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border-radius: 100px;
    background: transparent !important;
  }
  
  .tableContainer::-webkit-scrollbar-corner {
    background: transparent;
  }
  
  .headCell {
    padding: 4px !important;
    white-space: nowrap;
    border-bottom: 1px solid rgba(211, 211, 211, 0.4) !important;
  }
  
  .headFirstCell {
    border-radius: 10px 0 0 0;
    padding-left: 12px !important;
  }
  
  .headCellLast {
    border-radius: 0 10px 0 0;
    width: 1%;
    padding-right: 24px !important;
  }
  
  .sortIcon {
    width: 24px;
    height: 24px;
    color: var(--palette-secondary-darkBlue) !important;
  }
  
  .pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
  }
  