.typeaheadV3__assignContainer {
  display: flex;
  align-items: center;
  width: fit-content;
  height: 26px;
  border-radius: 30px;
  background-color: #f0f2f7;
  border: 1px solid #f0f2f7;
  padding: 0px 6px 0px 12px;
  cursor: pointer;
  position: relative;
}

.typeaheadV3__assignContainer:focus-visible {
  outline: 2px solid #5d36ef;
}

.typeaheadV3__assignContainer.selected {
  border: 1px solid #5d36ef;
}

.typeaheadV3__assignContainer.disabled {
  background-color: #f8f9fe !important;
}

.typeaheadV3__selectedContainer {
  background-color: #ebe5ff !important;
}

.typeaheadV3__selectLabel {
  font-family: Open Sans !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 19px !important;
  color: var(--palette-secondary-darkBlue) !important;
  transform: none !important;
  margin-bottom: 6px;
}

.typeaheadV3__assignTo {
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.46px;
  text-transform: capitalize;
  color: var(--palette-secondary-darkBlue);
}

.typeaheadV3__assignName {
  font-weight: 700;
}

.typeaheadV3__moreIcon {
  display: flex;
  align-items: center;
  margin-left: 6px;
  color: var(--palette-secondary-darkBlue);
  transform: rotate(0deg);
}

.typeaheadV3__moreIcon.open {
  transform: rotate(180deg);
}

.typeaheadV3__moreIcon.disabled {
  color: var(--palette-neutrals-greyMed) !important;
}

.typeaheadV3__popoverContent {
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  height: 100%;
}

.typeaheadV3__searchMembers {
  margin-right: 20px !important;
}

.typeaheadV3_inputRoot {
  border-radius: 8px !important;
  border: 1px solid rgba(211, 211, 211, 0.6) !important;
}

.typeaheadV3_inputInput {
  height: 22px !important;
  border-radius: 8px !important;
  padding: 9px 12px !important;
  border-color: transparent !important;
}

.typeaheadV3_inputNotchedOutline {
  border-color: transparent !important;
}

.typeaheadV3_inputFocused {
  border: 1px solid var(--palette-primary-purple) !important;
}

.typeaheadV3__listWrapper3 {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-height: 250px;
  padding-bottom: 12px;
}

.typeaheadV3__listWrapper3::-webkit-scrollbar {
  width: 8px;
}

.typeaheadV3__listWrapper3::-webkit-scrollbar-track {
  border-radius: 8px;
  margin-bottom: 16px;
}

.typeaheadV3__listWrapper3::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  background: #b8b9bb;
  border-radius: 8px;
}

.typeaheadV3__paper {
  margin-top: 8px;
  width: 530px;
  max-height: 320px !important;
  padding: 20px 0px 20px 20px;
  border-radius: 26px !important;
  overflow: hidden !important;
  box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.15) !important;
}

.typeaheadV3__listItem {
  margin-right: 20px !important;
  padding: 11px 12px;
  border-radius: 5px;
}
.typeaheadV3__listItem:hover {
  cursor: pointer;
  background: var(--palette-neutrals-grayBlue) !important;
}

.typeaheadV3__listItemSelected {
  background: var(--palette-options-hover) !important;
}

.typeaheadV3__errorColor {
  color: #f34e4e !important;
}

.typeaheadV3__placeholderColor {
  color: var(--palette-neutrals-greyMed) !important;
}

.typeaheadV3__errorBorder {
  border: 1px solid #f34e4e !important;
}
