.mainPageContainer {
  height: auto;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  touch-action: manipulation !important;
}

.mainPageLayout {
  display: flex;
  flex-grow: 1;
  height: calc(100vh - 48px);
  position: relative;
}

.content {
  padding-top: 8px;
  height: calc(100% - 8px);
}
