.mainContainer {
  height: calc(100vh - 48px);
  display: flex;
  flex-direction: column;
  touch-action: manipulation !important;
}

.resizeGroupPanel {
  padding: 0 !important;
}
