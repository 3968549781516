.noLogItemsWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.noLogItemsImage {
  width: 292px;
  height: 292px;
}

.noLogItemsLabel {
  max-width: 468px !important;
  font-weight: 700 !important;
  text-align: center;
  color: var(--palette-secondary-darkBlue);
  margin-bottom: 15px !important;
}
