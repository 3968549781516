.errorHandlerModal {
  width: 600px !important;
  max-height: 626px !important;
}

.errorHandlerContent {
  display: flex;
  flex-direction: column;
  overflow: hidden !important;
}

.errorHandlerTitle {
  justify-content: center !important;
}

.copyCodeBlock {
  text-align: center;
  margin-top: 16px;
}

.codeBlock {
  display: flex;
  flex-direction: column;
  max-height: 227px;
  margin-top: 16px !important;
  padding: 16px 24px;
  border-radius: 26px;
  background-color: #fff;
  white-space: pre-line !important;
  overflow-y: auto;
}

.codeBlock::-webkit-scrollbar-track {
  margin-top: 16px;
  margin-bottom: 16px;
}

.errorMessage {
  white-space: pre-line !important;
  overflow-wrap: break-word !important;
}
