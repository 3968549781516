.header {
  display: flex;
  justify-content: space-between;
}

.container {
  height: calc(100vh - 70px);
}

.title {
  margin-bottom: 8px;
}

.titleTextField {
  display: flex;
  padding-top: 3px;
}

.bordered {
  border: 1px solid #d3d3d3 !important;
}

.mainContainer {
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  background: #f8f8f8;
  border-radius: 8px;
  box-shadow: -1px 4px 11px rgba(142, 142, 142, 0.25);
  padding: 8px 0 0 0;
}

.detailContentContainer {
  box-sizing: border-box;
  background: #f8f8f8;
  border-radius: 8px;
  box-shadow: -1px 4px 11px rgba(142, 142, 142, 0.25);
  padding: 8px 0 0 0;
}

.leftContainer {
  min-width: none;
  width: 60%;
  float: left;
}

.rightContainer {
  width: 38%;
  min-height: 500px;
  padding: 8px 16px;
  float: left;
  margin-left: 8px;
}

.taskContent {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 8px;
}

.buttonsGroup {
  position: absolute !important;
  right: 10px;
  z-index: 100;
}

.organizationHeader {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0px 12px;
}

.titleWrapper {
  display: flex;
  gap: 8px;
  width: calc(100% - 140px);
}

.truncatedText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.buttonWrapper {
  display: flex;
  gap: 8px;
}

.linkButton {
  font-size: 13px !important;
  line-height: 16px !important;
  padding: 4px 10px;
  border: 1px solid var(--palette-neutrals-lightGrey);
  border-radius: 8px;
  background-color: white;
  text-decoration: none;
  white-space: nowrap;
}
