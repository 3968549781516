.row {
  display: flex;
  gap: 16px;
  align-items: center;
}

.uploader {
  margin-top: 12px !important;
  width: 100% !important;
}

.linkText {
  text-decoration: underline;
  font-style: italic;
  color: var(--palette-primary-purple) !important;
  cursor: pointer;
}
