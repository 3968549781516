.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-items: center;
}

.titleWrapper {
  display: flex;
  width: calc(100% - 240px);
  gap: 8px;
}

.descriptionSection {
  display: flex;
  gap: 8px;
  justify-content: flex-start;
}

.buttonsGroup {
  display: flex;
  gap: 4px;
  align-items: center;
}

.taskContent {
  display: flex;
  height: 100%;
  gap: 8px;
  overflow: auto;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  gap: 8px;
}

.buttonGroup {
  display: flex;
  gap: 4px;
  align-items: center;
}

.truncatedText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tabHeader {
  display: flex;
  gap: 16px;
  align-items: center;
  padding-bottom: 4px;
}

.row {
  display: flex;
}

.detailsRoot {
  height: fit-content !important;
}

.panel {
  padding: 0 8px;
}

.collapseButton {
  padding: 0 !important; 
  border-radius: 25%;
}

.active {
  border: 1px solid var(--palette-options-hover);
  color: var(--palette-primary-purple);
}
