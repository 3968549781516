.header {
  display: flex;
  justify-content: space-between;
  height: 28px;
  align-items: center;
}

.row {
  display: flex;
  gap: 8px;
}

.title {
  color: #081949;
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 143% !important;
  letter-spacing: 0.17px !important;
}

.content {
    margin: 8px 0 16px 16px;
}

.divider {
    margin-top: 10px !important;
}