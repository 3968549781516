.feedbackIconsContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.feedbackTooltip {
  max-width: 85px !important;
  text-align: center;
}

.popoverContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.closeButton {
  position: absolute;
  right: 12px;
  top: 10px;
  color: var(--palette-secondary-darkBlue);
}

.feedbackIcon {
  display: flex;
  align-items: center;
}

.feedbackIcon:hover,
.closeButton:hover,
.selected {
  color: var(--palette-primary-purple) !important;
  cursor: pointer;
}

.actionWrapper {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.feedbackContent {
  flex: 1;
  background-color: var(--palette-neutrals-grayBlue) !important;
}
