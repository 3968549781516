.detailContentContainer {
  box-sizing: border-box;
  height: 100%;
  padding: 8px;
  border-radius: 8px;
}

.header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.tabContainer {
  display: flex;
  gap: 8px;
}

.userContent {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
}

.detailsContainer {
  overflow: auto;
}

.panel {
  height: fit-content !important;
}

.row {
  display: flex;
  align-items: center;
  gap: 8px;
}

.fullName {
  width: calc(100% - 16px);
  max-width: 380px;
}

.buttonsWrapper {
  display: flex;
  gap: 4px;
}

.icon {
  color: var(--palette-secondary-darkBlue);
}

.path {
  text-align: left;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow-wrap: anywhere;
}
