body {
  font-family: 'Open Sans', sans-serif;
  background-color: #f0f2f7;
  overflow-y: hidden;
  margin: 0px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track {
  border-radius: 100px;
}
::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  background: #d8d9db;
  border-radius: 100px;
}
