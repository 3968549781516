.memberTable {
  border-radius: 8px !important;
  height: 100%;
}

.tableContainer {
  width: 100%;
  overflow: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 4px;
  height: calc(100% - 36px);
}

.checkboxCell {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px 3px 10px;
}
