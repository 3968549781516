.noMatchingWrapper {
  display: flex;
  height: calc(100% - 56px);
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin-top: 40px;
}

.noMatchingIcon {
  color: var(--palette-secondary-darkBlue);
  width: 56px !important;
  height: 56px !important;
  margin: 12px 0px;
}

.noMatchingLabel {
  max-width: 528px !important;
  text-align: center;
  color: var(--palette-neutrals-grayBlueDark);
  white-space: pre-line;
}
