.modalContainer {
  max-width: 1200px !important;
  width: unset !important;
  overflow: hidden;
}

.modalContent {
  overflow-y: auto;
  overflow-x: hidden;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  max-width: calc(100% - 120px);
  min-width: 400px;
  overflow: hidden;
  padding: 50px 70px;
  width: 100%;
  margin: auto;
  gap: 16px;
}

.buttonGroup {
  margin-top: 40px;
  gap: 8px;
  display: flex;
}

@media (max-width: 1280px) {
  .createTaskTitle {
    min-width: 100%;
  }
}

/* Mobile */
@media (max-width: 768px) {
  .row {
    flex-direction: column;
  }

  .buttonGroup {
    margin: 10px 0px 20px 0px;
    justify-content: center;
  }
}

/* Mobile */
@media (max-width: 768px) {
  .modalContainer {
    width: 95% !important;
  }

  .modalContent > div {
    padding: 30px;
    width: calc(100% - 60px);
  }
}

/* Tablet */
@media (min-width: 769px) and (max-width: 1280px) {
  .modalContainer {
    width: 90% !important;
  }

  .modalContent > div {
    padding: 40px 50px;
    width: calc(100% - 100px);
  }
}
