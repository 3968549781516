.header {
  display: flex;
  justify-content: space-between;
}

.row {
  display: flex;
  gap: 8px;
}

.indent {
  margin-left: 16px;
}
