.organizationHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 8px;
}

.organizationMainContainer {
  width: 100%;
  padding-top: 8px;
}

.button {
  width: 100px;
}

.content {
  display: flex;
  gap: 8px;
  height: 100%;
}
